* {
  margin: 0;
  padding: 0;

  font-family: Pretendard;
}

html,
body {
  width: 100%;
  min-width: 100%;

  background-color: var(--black);

  overflow-x: hidden;
}

#root {
  width: 100%;
  min-width: 100%;
}

button {
  border: 0;
  cursor: pointer;
}

input {
  outline: none;
}

textarea {
  outline: none;
}

:root {
  /* color */
  --black: #17171b;
  --grey500: #30303b;
  --grey300: #82828f;
  --grey100: #cfd1da;
  --white: #f7f8fc;
  --red: #ee3d59;
}

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css');
